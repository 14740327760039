<template>
  <div class="edp-backend">
    <edp-backend-user />

    <section class="edp-backend-middle">
      <edp-backend-menu />

      <!-- content -->
      <section class="edp-backend-content">
        <div class="edp-backend-content-out">
          <mainTitle>
            <span class="anyRating-main-title-titleWord">Evaluation Management / Publish Evaluation</span>
          </mainTitle>
          
          <el-card class="edp-backend-conditionSearch" body-style="width: 100%;" style="padding-top: 0;">
            <div slot="header" class="clearfix fac lh15">
              <span @click="backQuestionList" class="mr4 pot fac">
                <img src="@/src/assets/common/back.png" width="20px" height="21px" />
              </span>
              <strong>{{$t('questionnaire.publishEvaluation')}}</strong>
            </div>
            <el-form :model="question" ref="questionForm" label-position="left" label-suffix=":">
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item :label="$t('questionnaire.coverImage')" prop="coverImg">
                    <UploadImage
                      class="avatar-uploader-publish"
                      :disabled="true"
                      :limit="1"
                      :imgList="[{ name: 'cover-img', url: question.coverImg }]"
                      :preview="true"
                      :previewTitle="$t('questionnaire.coverImage')"
                      v-if="question.coverImg"
                    />
                    <span v-else>--</span>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item :label="$t('questionnaire.homePic')" prop="homePageUrl">
                    <UploadImage
                      class="avatar-uploader-publish"
                      :disabled="true"
                      :limit="1"
                      :imgList="[{ name: 'home-img', url: question.homePageUrl }]"
                      :preview="true"
                      :previewTitle="$t('questionnaire.homePic')"
                      v-if="question.homePageUrl"
                    />
                    <span v-else>--</span>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item :label="$t('questionnaire.endPic')" prop="endPageUrl">
                    <UploadImage
                      class="avatar-uploader-publish"
                      :disabled="true"
                      :limit="1"
                      :imgList="[{ name: 'end-img', url: question.endPageUrl }]"
                      :preview="true"
                      :previewTitle="$t('questionnaire.endPic')"
                      v-if="question.endPageUrl"
                    />
                    <span v-else>--</span>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item :label="$t('questionnaire.quesTitle')" prop="title" label-width="200px">
                <span class="color-teal">{{ question.title ? question.title : '--' }}</span>
              </el-form-item>
              <el-form-item :label="$t('questionnaire.publishStatus')" prop="status" label-width="200px">
                <span>{{ question.status == "1" ? $t('questionnaire.Published') : $t('questionnaire.Unpublished') }}</span>
              </el-form-item>
              <el-form-item :label="$t('questionnaire.welcomeMsg')" prop="greetWord" label-width="200px">
                <span style="white-space: pre-wrap;">{{ question.greetWord ? question.greetWord : '--' }}</span>
              </el-form-item>
              <el-form-item :label="$t('questionnaire.conclusion')" prop="conclusion" label-width="200px">
                <span style="white-space: pre-wrap;">{{ question.conclusion ? question.conclusion : '--' }}</span>
              </el-form-item>
            </el-form>
          </el-card>
          <el-card class="edp-backend-conditionSearch" body-style="width: 100%;">
            <el-form :model="publish.form" :rules="publish.rules" ref="publishForm" label-position="left" label-suffix=":">
              <el-form-item :label="$t('questionnaire.surveyClosingDate')" prop="endTime" label-width="200px">
                <el-date-picker
                  v-model="publish.form.endTime"
                  format="yyyy-MM-dd HH:mm:ss"
                  :placeholder="`${$t('edpBackendCommon.choose')}${$t('questionnaire.surveyClosingDate')}`"
                  style="width: 300px;"
                  type="datetime"
                />
              </el-form-item>
              <el-form-item :label="$t('questionnaire.anonymous')" prop="anonymousFlag" label-width="200px">
                <el-radio-group v-model="publish.form.anonymousFlag">
                  <el-radio :label="1">Y</el-radio>
                  <el-radio :label="0">N</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                 :label="$t('questionnaire.authentication')"
                 prop="releaseChannels"
                 label-width="200px"
                 v-if="publish.form.anonymousFlag === 0"
               >
                <el-checkbox-group v-model="publish.form.releaseChannels">
                  <el-checkbox label="adfs" name="authentication">ADFS</el-checkbox>
                  <el-checkbox label="app" name="authentication">Employee APP</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item class="fje">
                <el-button @click="backQuestionList">{{ $t('edpBackendCommon.return') }}</el-button>
                <el-button type="primary" @click="submitForm">{{ $t('questionnaireTemplate.Publish') }}</el-button>
              </el-form-item>
            </el-form>
          </el-card>
        </div>
      </section>
      <!-- content end -->
    </section>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import UploadImage from "@/src/components/upload/entryUpload.vue";
export default {
  name: "publish",
  components: {
    UploadImage,
  },
  data() {
    return {
      publicStatusList: [
        {
          label: this.$t('questionnaireTemplate.Published'),
          value: "1",
        },
        {
          label: this.$t('questionnaireTemplate.Unpublished'),
          value: "2",
        },
      ],
      templateData: [],
      dateils: {
        pageAddress: "string",
        publishSystems: "string",
        qrUrl: "string",
      },
      question: {
        id: undefined,
      },
      publish: {
        form: {
          anonymousFlag: 1,
          releaseChannels: [],
        },
        rules: {
          anonymousFlag: [
            { required: true, message: `${this.$t('edpBackendCommon.choose')}${this.$t('questionnaire.anonymous')}`, trigger: 'change' }
          ],
          endTime: [
            { type: 'date', required: false, message: `${this.$t('edpBackendCommon.choose')}${this.$t('questionnaire.surveyClosingDate')}`, trigger: 'change' }
          ],
          releaseChannels: [
            { type: 'array', required: true, message: `${this.$t('edpBackendCommon.choose')}${this.$t('questionnaire.authentication')}`, trigger: 'change' }
          ],
        }
      },
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(o, n) {},
    },
  },
  mounted() {
    this.id = this.$route.query.id;
    this.code = this.$route.query.code;
    if (this.id) {
      this.question.id = this.id;
      this.viewInit(this.id);
    }
    if (this.code) {
      this.question.code = this.id;
    }
  },
  created() {},
  methods: {
    ...mapActions({
      templateList: "questionnaireTemplate/templateList",
      surveyView: "questionnaireTemplate/surveyView",
      publishQuestion: "questionnaireTemplate/publishQuestion",
      surveyDelete: "questionnaireTemplate/surveyDelete",
      offlineQuestion: "questionnaireTemplate/offlineQuestion",
      onlineQuestion: "questionnaireTemplate/onlineQuestion",
      turnoffQuestion: "questionnaireTemplate/turnoffQuestion",
      turnonQuestion: "questionnaireTemplate/turnonQuestion",
      surveyQrcode: "questionnaireTemplate/surveyQrcode"
    }),
    submitForm() {
      this.$refs.publishForm.validate((valid) => {
        if (valid) {
          const params = {
            id: this.question.id,
            surveyId: this.question.id,
            anonymousFlag: this.publish.form.anonymousFlag,
            endTime: this.publish.form.endTime ? this.utils.dateFormatFn(this.publish.form.endTime, 'yyyy-MM-dd HH:mm:ss') : null,
            releaseChannels: this.publish.form.releaseChannels && this.publish.form.releaseChannels.length ? this.publish.form.releaseChannels : ['h5'],
          }
          this.$confirm(`${this.$t('edpBackendCommon.confirm')} ${this.$t('questionnaire.Publish')} ${this.$t('questionnaire.thisquestionnaire')}`, this.$t('edpBackendCommon.noticeTitle'), {
            confirmButtonText: this.$t('questionnaire.Publish'),
            cancelButtonText: this.$t('edpBackendCommon.return'),
            center: false,
          }).then(() => {
            this.publishQuestion(params).then(response => {
              if (response.success) {
                this.$confirm(this.$t('questionnaire.publishingSucceededWhetherToGoToShare'), this.$t('edpBackendCommon.noticeTitle'), {
                  confirmButtonText: this.$t('questionnaire.Gotoshare'),
                  cancelButtonText: this.$t('edpBackendCommon.return'),
                  center: false,
                }).then(() => {
                  this.$router.push({path: 'backend-questionnaire-share', query: { id: this.id, code: this.code }});
                }).catch(() => {
                  this.$router.go(-1);
                })
              } else {
                this.$message({
                  type: "warning",
                  message: response.msg,
                });
              }
            })
          })
        } else {
          console.info('error submit!!');
          return false;
        }
      });
    },
    viewInit(id) {
      this.surveyView({
        id: id,
        param: this.$route.query,
      }).then((res) => {
        if (res.success === true) {
          this.question = res.data;
          if (res.data.coverImg) {
            this.fileImgList = [{ name: "cover image", url: res.data.coverImg }];
          }
          if (res.data.homePageUrl) {
            this.homeImgList = [{ name: "home page image", url: res.data.homePageUrl }];
          }
          if (res.data.endPageUrl) {
            this.endImgList = [{ name: "end page image", url: res.data.endPageUrl }];
          }

          this.question.publishSystems =
            this.question.publishSystems ? this.question.publishSystems.split(",") : "";
          if (this.question.rewardType) {
            this.question.rewardType = this.question.rewardType + "";
          }
          if (this.question.isPageForEach) {
            this.question.isPageForEach = this.question.isPageForEach + "";
          }
          if (this.question.questions && this.question.questions.length > 0) {
            const questionList = this.question.questions;
            for (let i = 0; i < questionList.length; i++) {
              const qt = questionList[i];
              this.$set(qt, "id", qt.type);
              if (qt.options && qt.options.length > 0) {
                const fileList = [];
                for (let j = 0; j < qt.options.length; j++) {
                  if (qt.type == "2") {
                    if (qt.options[j].isChoose == "1") {
                      qt.options[j].isChoose = true;
                    }
                  }
                  if (qt.type == "3" || qt.type == "4") {
                    const file = {
                      url: qt.options[j].content,
                    };
                    fileList.push(file);
                    if (file.url == qt.options[j].content) {
                      qt.options[j].fileParam = file;
                    }
                  }
                  this.$set(qt, "fileList", fileList);
                  if (qt.type == "8") {
                    qt.options[j].answer = Number(qt.options[j].answer);
                  }
                }
              }
              console.log("qt.options", qt);
            }
          }
        }
      });
    },
    backQuestionList() {
      this.$router.push({ path: 'backend-questionnaire' });
    },
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.edp-backend-content-out {
  .color-teal {
    color: #3E886D;
  }
  .avatar-uploader-publish {
    .el-upload.el-upload--picture-card {
      display: none;
    }
    .el-upload-list.el-upload-list--picture-card.is-disabled {
      display: inline-flex;
      .el-upload-list__item.is-success {
        width: 80px;
        height: 80px;
        margin: 0;
      }
    }
  }
}
.searchTitle {
  text-align: left;
  margin-top: 16px;
  img {
    position: absolute;
    margin-top: 2px;
  }
  span {
    margin-left: 20px;
  }
}
.flex-buttons {
  top: 14px;
}
.searchBtn {
  text-align: right;
}
.mt20 {
  margin-top: 16px;
}
.row {
  margin-bottom: 18px;
}
.ftClas {
  color: #3E886D;
  cursor: pointer;
}
.share-wrap {
  border-radius: 10px;
  >>> .el-dialog__header {
    background: rgb(242, 242, 242);
    .titles {
      background: rgb(242, 242, 242);
      width: 90%;
      font-size: 14px;
      color: rgb(0, 9, 91);
      text-align: right;
      i {
        font-size: 14px;
      }
    }
    .el-dialog__close:before {
      font-size: 22px;
      color: #333;
    }
  }
  h4 {
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
  }
  .desc {
    font-size: 14px;
    color: rgb(110, 110, 110);
    text-align: center;
  }
  .qrcode {
    display: block;
    width: 140px;
    height: 140px;
    margin: 10px auto;
    padding: 4px;
    border: 1px solid rgb(153, 153, 153);
  }
  .links {
    text-align: center;
    font-size: 14px;
    color: rgb(153, 153, 153);
    span {
      font-size: 12px;
      color: rgb(23, 0, 244);
      text-decoration: underline;
    }
  }
  .copy-btn {
    display: block;
    margin: 5px auto;
  }
}
</style>
<style lang="less">
.questionnaires {
  button {
    cursor: pointer;
  }
  .el-dropdown-link {
    cursor: pointer;
  }
  .el-icon-arrow-down {
    font-size: 14px;
  }
}
</style>
